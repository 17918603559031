<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm" @keyup.enter.native="fenye()">
            <el-form-item prop="name" label="标引名称：">
                <el-input size="small" clearable v-model="searchForm.name"
                          placeholder="请输入标引名称关键词" maxlength="66" ></el-input>
            </el-form-item>
            <el-form-item prop="data" label="标引时间：">
                <el-date-picker style="width: 240px;"
                        size="small"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        v-model="searchForm.searchDates"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="fenye()" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetForm('searchForm')" size="small"
                           icon="el-icon-refresh-right">
                    重置
                </el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button size="small" icon="el-icon-circle-check" type="primary" v-if="tabIndex==0 && hasPermission('indexing:list:if')" @click="toExamines()">批量通过</el-button>
                <el-button size="small" icon="el-icon-circle-close" type="danger" v-if="tabIndex==0 && hasPermission('indexing:list:reject')" @click="rejects()">批量驳回</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-tabs v-model="tabIndex" size="small" @tab-click="fenye()">
                <el-tab-pane label="全部标引" name="4"></el-tab-pane>
                <el-tab-pane label="待审核标引" name="0"></el-tab-pane>
                <el-tab-pane label="已通过标引" name="1"></el-tab-pane>
                <el-tab-pane label="已驳回标引" name="2"></el-tab-pane>
                <el-tab-pane label="已删除标引" name="5"></el-tab-pane>
            </el-tabs>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    ref="rightList"
                    size="small"
                    height="calc(100vh - 350px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="id"
                        show-overflow-tooltip sortable
                        label="序号">
                </el-table-column>
                <el-table-column
                        prop="labelName"
                        show-overflow-tooltip
                        label="标引名称">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip sortable
                        label="标引时间">
                </el-table-column>
                <el-table-column
                        prop="name"
                        show-overflow-tooltip
                        label="标引人">
                </el-table-column>
                <el-table-column
                        prop="status"
                        show-overflow-tooltip
                        label="标引状态">
                    <template slot-scope="scope">
                        <span>{{scope.row.status==0 ? '待审核':scope.row.status==1 ?'审核通过':'审核驳回'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="labelType"
                        show-overflow-tooltip
                        label="标引类型">
                </el-table-column>
                <el-table-column
                        prop="labelDescription"
                        show-overflow-tooltip
                        label="标引描述">
                </el-table-column>
                <el-table-column
                        prop="resourceName"
                        show-overflow-tooltip
                        label="标引资源名">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作" v-if="tabIndex != 5">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-if="scope.row.status==0 && hasPermission('indexing:list:if')"
                                   @click="toExamines(scope.row.id)">通过
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.status==0 && hasPermission('indexing:list:reject')"
                                   @click="rejects(scope.row.id)">驳回
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.status==1 && hasPermission('indexing:list:edit')"
                                   @click="edit(scope.row)">修改
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.status != 0 && hasPermission('indexing:list:del')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="修改标引描述" :close-on-click-modal="false" append-to-body :visible.sync="visible" >
            <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model.trim="editData.labelDescription" maxlength="200"
                    show-word-limit>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "IndexingManagement",
        data() {
            return {
                tabIndex: '4',
                searchForm: {
                    name: '',//搜索关键词
                    searchDates: ['', ''], //时间
                },
                loading: false,
                dataList: [],//列表
                dataListSelections: [],//多选列表
                pageNo: 1,
                pageSize: 10,
                total: 0,
                visible: false,
                editData: {
                    id: '',
                    labelDescription: '',
                },
            }
        },
        mounted() {
            this.getList();
        },

        methods: {
            //重置分页
            fenye() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.getList()
            },

            //进入页面数据请求
            getList() {
                this.loading = true
                if (!this.searchForm.searchDates) {
                    this.searchForm.searchDates = ['', ''];
                }
                this.$axios(this.api.resources.labelList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'status': this.tabIndex == '5' ? '4' : this.tabIndex,
                    'delFlag': this.tabIndex == '5' ? '1' : '0',
                    'search': this.searchForm.name,
                    'startTime': this.searchForm.searchDates[0],
                    'endTime': this.searchForm.searchDates[1]
                }, 'get').then(res => {
                    if (res.status) {
                        this.loading = false;
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.pageNo > 1) {
                            this.pageNo--
                            this.getList()
                        }
                        this.total = parseInt(res.data.total);
                        this.$nextTick(() => {
                            this.$refs.rightList.doLayout()
                        })
                    }
                })
            },


            // 修改
            edit(row) {
                this.recover(this.editData, row);
                this.visible = true
            },

            // 删除
            del(id) {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确定删除该标引？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '点击确定后，该资源不再显示该标引信息')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$axios(this.api.digital.batchRemove, [id], 'post').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                        } else {
                            this.$message.error(data.msg)
                        }
                        this.getList()
                    })
                });
            },

            doSubmit() {
                if(!this.editData.labelDescription) {
                    this.$message.warning('请输入内容')
                    return
                }
                this.$axios(this.api.digital.resourcelabellink, this.editData, 'put').then(res => {
                    if (res.status) {
                        this.$message.success('修改成功')
                        this.visible = false
                    } else {
                        this.$message.error('修改失败')
                    }
                    this.getList();
                })
            },

            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.getList();
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.getList();
            },

            //重置
            resetForm(formName) {
                this.searchForm.name = ''
                this.searchForm.searchDates = ['', '']
                //this.$refs.[formName].resetFields()
                this.$nextTick(() => {
                    this.getList()
                })
            },

            //审核 多
            toExamines(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                if (!id && this.dataListSelections.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                } else {
                    //请求接口
                    this.$confirm('此操作将审核该数据, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$axios(this.api.resources.batchUpdateLabel, {
                            'staus': 1, 'ids': ids
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('通过成功')
                            } else {
                                this.$message.error(res.msg)
                            }
                            this.getList();
                        })

                    }).catch(() => {
                    });
                }
            },

            //驳回 多
            rejects(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                if (!id && this.dataListSelections.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                } else {
                    //请求接口
                    this.$confirm('此操作将永久驳回该数据, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        //删除接口
                        this.$axios(this.api.resources.batchUpdateLabel, {
                            'staus': 2, 'ids': ids
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('驳回成功')
                            } else {
                                this.$message.error(res.msg)
                            }
                            this.getList();
                        })
                    }).catch(() => {
                    });
                }
            },


        }
    }
</script>

<style scoped>

</style>
